export const customTheme = {
  token: {
    colorPrimary: "#2563EB",
    colorBorder: "#D0D5DD",
    colorText: "#101828",
  },
  components: {
    Typography: {
      colorText: "#101828",
    },
    Button: {
      colorPrimaryHover: "#2F57E3",
      colorPrimaryActive: "#406AFF",
      colorBorder: "#D0D5DD",
      colorText: "#344054",
      colorError: "#D92D20",
      colorErrorHover: "#B42318",
      colorErrorActive: "#D92D20",
      colorErrorBorderHover: "#FDA29B",
      colorLink: "#475467",
      colorLinkHover: "#344054",
      colorLinkActive: "#475467",
    },
    Progress: {
      defaultColor: "#406AFF",
    },

    Input: {
      colorborder: "#D0D5DD",
      colorPrimaryHover: "#D0D5DD",
      colorError: "#FDA29B",
      colorErrorBorderHover: "#FDA29B",
    },
    Form: {
      colorError: "#F04438",
      fontSize: 14,
      lineHeight: "20px",
    },
    Radio: {
      buttonSolidCheckedActiveBg: "#fff",
      buttonSolidCheckedColor: "#406AFF",
    },
    Tag: {
      defaultBg: "#F9FAFB",
      defaultColor: "#344054",
      colorBorder: "#EAECF0",
    },
  },
};
