import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { getItem } from "../../helpers/localStorage";
import {
  USER,
  connectionAuthorizationOptions,
  connectionGivenOptions,
  connectionTypeOptions,
  meterConnectionStatusOptions,
  municipalTapOptions,
  propertyTypeOptions,
} from "../../constants/defaultKeys";
import { uploadExcelFileApi } from "../../services/was.services";

const { Option } = Select;

const ExcelDataTable = ({ data, setData }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [tapObj, setTapObj] = useState({});

  const userDetails = JSON.parse(getItem(USER));
  const [form] = Form.useForm();

  useEffect(() => {
    let initialValues = {},
      currTapObj = {};
    data?.map((item) => {
      let curr_municipal_tap = municipalTapOptions?.includes(
        item?.municipal_tap
      )
        ? item?.municipal_tap
        : undefined;
      initialValues[item?.key] = {
        ...item,
        no_floors: Number(item?.no_floors) ? item?.no_floors : undefined,
        no_flats: Number(item?.no_flats) ? item?.no_flats : undefined,
        property_tax_Assessment_no: Number(item?.property_tax_Assessment_no)
          ? item?.property_tax_Assessment_no
          : undefined,
        property_type: propertyTypeOptions?.includes(item?.property_type)
          ? item?.property_type
          : undefined,
        municipal_tap: curr_municipal_tap,
        connection_type:
          curr_municipal_tap !== "No" &&
          connectionTypeOptions?.includes(item?.connection_type)
            ? item?.connection_type
            : undefined,
        connection_authorization:
          curr_municipal_tap !== "No" &&
          connectionAuthorizationOptions?.includes(
            item?.connection_authorization
          )
            ? item?.connection_authorization
            : undefined,
        service_no: curr_municipal_tap === "No" ? undefined : item?.service_no,
        connection_given:
          curr_municipal_tap !== "No" &&
          connectionGivenOptions?.includes(item?.connection_given)
            ? item?.connection_given
            : undefined,
        meter_connection_status:
          curr_municipal_tap !== "No" &&
          meterConnectionStatusOptions?.includes(item?.meter_connection_status)
            ? item?.meter_connection_status
            : undefined,
      };
      currTapObj[item?.key] = item?.municipal_tap;
    });

    setTapObj(currTapObj);
    form.setFieldsValue(initialValues);
  }, [data]);

  const onFinish = async (values) => {
    // console.log("form-values", values);
    let payload_data = [];
    Object.keys(values)?.map((k) => {
      payload_data.push(values[k]);
    });

    setBtnLoading(true);
    try {
      const res = await uploadExcelFileApi({ data: payload_data });
      message.success(res?.data?.message);
      setData([]);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleTapChange = (value, key) => {
    let curr_tap_obj = { ...tapObj };
    curr_tap_obj[key] = value;
    setTapObj(curr_tap_obj);
    if (value === "No") {
      form.setFieldValue([key, "connection_type"], undefined);
      form.setFieldValue([key, "connection_authorization"], undefined);
      form.setFieldValue([key, "service_no"], undefined);
      form.setFieldValue([key, "connection_given"], undefined);
      form.setFieldValue([key, "meter_connection_status"], undefined);
    }
  };

  const tableFormItem = (
    id,
    name,
    required,
    itemType,
    disabled,
    dataList = []
  ) => {
    return (
      <Form.Item
        name={[id, name]}
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: required,
            message: "Required",
          },
        ]}
      >
        {itemType === "selectInput" ? (
          <Select disabled={disabled}>
            {dataList?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ) : itemType === "number" ? (
          <InputNumber disabled={disabled} />
        ) : (
          <Input disabled={disabled} />
        )}
      </Form.Item>
    );
  };

  const tableColumns = [
    {
      title: "Secretariat No.",
      dataIndex: "secretariat_no",
      key: "secretariat_no",
      fixed: "left",
      width: 200,
      render: (val, record) => (
        <Form.Item
          name={[record?.key, "secretariat_no"]}
          style={{ marginBottom: 0 }}
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (value == userDetails?.region?.number) {
                    callback();
                  } else {
                    callback("Please enter valid secretariat no.");
                  }
                } else {
                  callback("Please enter secretariat no.");
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Secretariat Code",
      dataIndex: "secretariat_code",
      key: "secretariat_code",
      fixed: "left",
      width: 200,
      render: (val, record) => (
        <Form.Item
          name={[record?.key, "secretariat_code"]}
          style={{ marginBottom: 0 }}
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (value == userDetails?.region?.code) {
                    callback();
                  } else {
                    callback("Please enter valid secretariat code");
                  }
                } else {
                  callback("Please enter secretariat code");
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Secretariat Name",
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      width: 200,
      render: (val, record) =>
        tableFormItem(record?.key, "secretariat_name", true, "input", false),
    },
    {
      title: "Street Name",
      dataIndex: "street_name",
      key: "street_name",
      width: 200,
      render: (val, record) =>
        tableFormItem(record?.key, "street_name", true, "input", false),
    },
    {
      title: "Door No.",
      dataIndex: "door_no",
      key: "door_no",
      width: 200,
      render: (val, record) => (
        <Form.Item
          name={[record?.key, "door_no"]}
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: "Required",
            },
            {
              pattern: new RegExp("\\d"),
              message: "Please enter valid door no.",
            },
          ]}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "House Owner Name",
      dataIndex: "house_owner_name",
      key: "house_owner_name",
      width: 200,
      render: (val, record) =>
        tableFormItem(record?.key, "house_owner_name", false, "input", false),
    },
    {
      title: "House Owner Contact",
      dataIndex: "house_owner_contact",
      key: "house_owner_contact",
      width: 200,
      render: (val, record) => (
        <Form.Item
          name={[record?.key, "house_owner_contact"]}
          style={{ marginBottom: 0 }}
          rules={[
            {
              pattern: new RegExp("^[0-9]{10}$"),
              message: "Please enter valid mobile number",
            },
          ]}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Property Tax Assessment No.",
      dataIndex: "property_tax_Assessment_no",
      key: "property_tax_Assessment_no",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "property_tax_Assessment_no",
          false,
          "number",
          false
        ),
    },
    {
      title: "Property Type",
      dataIndex: "property_type",
      key: "property_type",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "property_type",
          false,
          "selectInput",
          false,
          propertyTypeOptions
        ),
    },
    {
      title: "Floors Count",
      dataIndex: "no_floors",
      key: "no_floors",
      width: 200,
      render: (val, record) =>
        tableFormItem(record?.key, "no_floors", false, "number", false),
    },
    {
      title: "Flats Count",
      dataIndex: "no_flats",
      key: "no_flats",
      width: 200,
      render: (val, record) =>
        tableFormItem(record?.key, "no_flats", false, "number", false),
    },
    {
      title: "Municipal Tap",
      dataIndex: "municipal_tap",
      key: "municipal_tap",
      width: 200,
      render: (val, record) => (
        <Form.Item
          name={[record?.key, "municipal_tap"]}
          style={{ marginBottom: 0 }}
        >
          <Select onChange={(value) => handleTapChange(value, record?.key)}>
            {municipalTapOptions?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Connection Type",
      dataIndex: "connection_type",
      key: "connection_type",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "connection_type",
          false,
          "selectInput",
          tapObj[record?.key] === "No" ? true : false,
          connectionTypeOptions
        ),
    },
    {
      title: "Connection Authorization",
      dataIndex: "connection_authorization",
      key: "connection_authorization",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "connection_authorization",
          false,
          "selectInput",
          tapObj[record?.key] === "No" ? true : false,
          connectionAuthorizationOptions
        ),
    },
    {
      title: "Service No.",
      dataIndex: "service_no",
      key: "service_no",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "service_no",
          false,
          "input",
          tapObj[record?.key] === "No" ? true : false
        ),
    },
    {
      title: "Connection Given",
      dataIndex: "connection_given",
      key: "connection_given",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "connection_given",
          false,
          "selectInput",
          tapObj[record?.key] === "No" ? true : false,
          connectionGivenOptions
        ),
    },
    {
      title: "Meter Connection Status",
      dataIndex: "meter_connection_status",
      key: "meter_connection_status",
      width: 200,
      render: (val, record) =>
        tableFormItem(
          record?.key,
          "meter_connection_status",
          false,
          "selectInput",
          tapObj[record?.key] === "No" ? true : false,
          meterConnectionStatusOptions
        ),
    },
  ];

  return (
    <Form
      form={form}
      name="excel_file_upload_form"
      onFinish={onFinish}
      onFinishFailed={(values) =>
        message.error("Please ensure that all the fields are correct")
      }
    >
      <Table
        bordered
        title={() => (
          <Typography style={{ fontSize: 18 }}>
            <span style={{ fontWeight: 600 }}>{data?.length}</span> records
          </Typography>
        )}
        columns={tableColumns}
        dataSource={data || []}
        loading={false}
        pagination={false}
        scroll={{
          y: 550,
          x: 600,
        }}
      />
      <Form.Item style={{ textAlign: "right", marginTop: 20 }}>
        <Button type="primary" htmlType="submit" loading={btnLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ExcelDataTable;
