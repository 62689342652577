import { Col, Mentions, Menu, Row, Radio, Card } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { menuItems } from "../../constants/commonItems";
import "./Dashboard.css";
import GeoTagging from "./GeoTagging/GeoTagging";
import GeoTaggingTable from "./GeoTagging/GeoTaggingTable";
import InspectionTabs from "./Inspections/InspectionTabs";
import MapTabs from "./Map/MapTabs";
import Overview from "./Overview/Overview";
import TicketTabs from "./Tickets/TicketTabs";
const Dashboard = () => {
  const query = useParams();
  const navigate = useNavigate();

  const onMenuClick = (e) => {
    const selectedTab = e.target.value;
    selectedTab === "inspections"
      ? navigate(`/dashboard/${selectedTab}/wqm/fortnight-1`)
      : selectedTab === "map"
      ? navigate(`/dashboard/${selectedTab}/water_quantity`)
      : selectedTab === "tickets"
      ? navigate(`/dashboard/${selectedTab}/water_quantity_quality`)
      : navigate(`/dashboard/${selectedTab}`);
  };

  return (
    <Card>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Radio.Group
            // style={{ marginBottom: "10px" }}
            options={menuItems}
            onChange={onMenuClick}
            value={query?.tab}
            className="dashboard-menu"
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
        <Col span={24}>
          {query?.tab === "geo" && <GeoTagging />}
          {query?.tab === "inspections" && <InspectionTabs />}
          {query?.tab === "map" && <MapTabs />}
          {query?.tab === "tickets" && <TicketTabs />}
          {query?.tab === "overview" && <Overview />}
        </Col>
      </Row>
    </Card>
  );
};
export default Dashboard;
