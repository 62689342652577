import axiosInstance from "../config/axios.config";

export function fetchWqmSurveyAPI(params) {
  return axiosInstance.get(
    `wqm/fetch-water-quality-quantity-survey-data/?${params}`
  );
}
export function fetchWqmTicketsAPI(params) {
  return axiosInstance.get(`wqm/water-quality-tickets/?${params}`);
}
export function fetchWlmTicketsAPI(params) {
  return axiosInstance.get(`wqm/water-leakage-tickets/?${params}`);
}
