import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tag, Typography, Space, Avatar, Skeleton } from "antd";
import { fetchOverviewAPI } from "../../../services/geo.services";
import ActionsPieChart from "./ActionsPieChart";
import OverviewCards from "./OverviewCards";
import QcBarGraph from "./QcBarGraph";
import GrievancesLineChart from "./GrievancesLineChart";
import InspectionsBarChart from "./InspectionsBarChart";
import CustomActionProgress from "./CustomActionProgress";
const { Text } = Typography;
const Overview = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    fetchOverviewAPI()
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  function graphLegend(firstColor, secondColor, firstText, secondText) {
    return (
      <Space>
        <Avatar
          shape="circle"
          size={12}
          style={{ backgroundColor: firstColor }}
        />
        <Text>{firstText}</Text>
        <Avatar
          shape="circle"
          size={12}
          style={{ backgroundColor: secondColor }}
        />
        <Text>{secondText}</Text>
      </Space>
    );
  }
  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <OverviewCards data={data} />
          </Col>
          <Col span={8}>
            <Card
              className="cardStyles"
              title={
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontWeight: "600", fontSize: "20px" }}>
                    Tickets
                  </Text>

                  <Tag
                    style={{
                      padding: "6px",
                      fontSize: "16px",
                      borderRadius: "16px",
                    }}
                    color="blue"
                  >
                    {`Total:${data?.total_tickets_raised}`}
                  </Tag>
                </Row>
              }
            >
              <ActionsPieChart data={data?.pie_chart} />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              className="cardStyles"
              title={
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontWeight: "600", fontSize: "20px" }}>
                    Quality check by AE
                  </Text>

                  {graphLegend("#2563EB", "#F97066", "Verified", "Pending")}
                </Row>
              }
            >
              <QcBarGraph data={data?.ae_bar_chart} />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              className="cardStyles"
              title={
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontWeight: "600", fontSize: "20px" }}>
                    Open Grievances
                  </Text>
                </Row>
              }
            >
              <GrievancesLineChart data={data?.open_tickets_graph} />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="cardStyles"
              title={
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontWeight: "600", fontSize: "20px" }}>
                    Household Inspections
                  </Text>
                  {graphLegend("#2563EB", "#F97066", "Taken", "To Be Taken")}
                </Row>
              }
            >
              <Row>
                <Col span={24}>
                  <InspectionsBarChart data={data?.inspections_bar_chart} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontWeight: "600", fontSize: "20px" }}>
                    Tickets Summary
                  </Text>
                  {graphLegend(
                    "#F97066",
                    "#2563EB",

                    "Pending",
                    "Resolved"
                  )}
                  <Tag
                    style={{
                      marginRight: "10px",
                      cursor: "auto",
                      padding: "6px",
                      fontSize: "16px",

                      borderRadius: "18px",
                    }}
                    color="blue"
                  >
                    Overall:{" "}
                    {data?.tickets_summary?.total_tickets_raised_last_90_days}
                  </Tag>
                </Row>
              }
              className="cardStyles"
            >
              <CustomActionProgress
                data={data?.tickets_summary?.tickets_summary}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
export default Overview;
