export const TOKEN_KEY = "token";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const USER = "user";
export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgB5tlBN1rXogPzl+9ESgfL0
dCuVYY19DVUCUuSezX0T6/XvbQOlIDyKP8A8deVxjvQ6pvfpslAG39mIT2D80UKh
XsOANQ600KV15+aBH3rPtenMV2/nN8YeAoQNju8buBb22Zi7e/Md+g7RY5TOli+w
CEIyVXGtR9FPnR6S2fVIPEkzMb4sjhdlco6XNruh+gOZ7oRiWoEU1sEdWIjTfp4+
0f31e8f5S1d7whoyJH7SznCJdSDq4xPSy93HdyPEtZeDV181ru4BwjkfSxmfcrGq
EmdN9oMN4vh/AiIrAAH3/m9d2kbjZf8yeqK4DUEwhXTSrJTkC3nqgZFxNsavGpUd
OTq7hDXjttml9N+e+fnasfH/Bm/4es3pr0m4vtzAKPNRREo7AeIBrC0sK7FuPePx
3OLUmI+iNgRAehMPt3Mt0hSlJDi5pCauZClWYDgSf6MOtm0hAi/xovaz4rc7zZby
+lN0L5APiq7DHeHVm9p+aBhJJ4jcwtM0CbMo0JbSEjQejVDDWUizXofueR13Sqqm
hyC4fF+Kp7IqNJEEjGfH52Pp2RIGdPbGltnwaLmmHPNuuPM23VnPqqtVGcv/Q6kP
noecBtBng1vlRxdTBmSZQ76zbWQLmqO/5vQNmjejBlgNlM7mncSkY743FZ/Tma+W
tUjtERHXp4OASUUlk8WYKwIDAQAB
-----END PUBLIC KEY-----
`;
export const colors = [
  "#2c699a",
  "#048ba8",
  "#0db39e",
  "#83e377",
  "#54478c",
  "#d81159ff",
  "#8f2d56ff",
  "#cc4400",
  "#000077",
  "#b9e769",
  "#efea5a",
  "#f1c453",
  "#f29e4c",
  "#d66915",
  "#e08e29",
  "#f0c761",
  "#7cc6de",
  "#3890bc",
  "#be1e2dff",
  "#1c489a",
  "#006ba6ff",
  "#471ca8ff",
  "#884ab2ff",
  "#72bad5ff",
  "#03324eff",
  "#ef4043ff",
  "#16db93",
  "#c43240ff",
  "#52ace4",
];

export const sampleExcelFormatUrl =
  "https://wqm-resources.s3.ap-south-1.amazonaws.com/Sec+Code+%26+No.xlsx";

export const propertyTypeOptions = [
  "Residential",
  "Mixed",
  "Non Residential",
  "Commercial",
  "Others",
];
export const municipalTapOptions = ["Yes", "No"];
export const connectionTypeOptions = [
  "Residential",
  "Bulk Residential",
  "Commercial",
  "Industrial",
];
export const connectionAuthorizationOptions = ["Authorized", "Un-Authorized"];
export const connectionGivenOptions = [
  "OLD Net Work",
  "New net Work (MEGA Blue MDPE pipe)",
];
export const meterConnectionStatusOptions = ["Working", "Not Working"];
