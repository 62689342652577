import { Typography } from "antd";

export const geoSecColumns = [
  {
    title: "Secretariat Code",
    key: "secretariat_code",
    dataIndex: "secretariat_code",
    sorter: (a, b) => a?.secretariat_code - b?.secretariat_code,
  },
  {
    title: "Total No. of Streets",
    key: "total_streets",
    dataIndex: "total_streets",
    sorter: (a, b) => a?.total_streets - b?.total_streets,
  },
  {
    title: "Total No. of Households",
    key: "total_households",
    dataIndex: "total_households",
    sorter: (a, b) => a?.total_households - b?.total_households,
  },
  {
    title: "Households Tagged with Geo-Coordinates",
    key: "total_households_tagged_geo_tagging",
    dataIndex: "total_households_tagged_geo_tagging",
    sorter: (a, b) =>
      a?.total_households_tagged_geo_tagging -
      b?.total_households_tagged_geo_tagging,
  },
];
export const geoStreetColumns = [
  {
    title: "Land Mark of the Street",
    key: "land_mark",
    dataIndex: "land_mark",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Total No. of Households",
    key: "total_households",
    dataIndex: "total_households",
    sorter: (a, b) => a?.total_households - b?.total_households,
  },
  {
    title: "Households Tagged with Geo-Coordinates",
    key: "total_households_tagged_geo_tagging",
    dataIndex: "total_households_tagged_geo_tagging",
    sorter: (a, b) =>
      a?.total_households_tagged_geo_tagging -
      b?.total_households_tagged_geo_tagging,
  },
];
export const geoHouseColumns = [
  {
    title: "Name of the House Owner",
    key: "house_hold_owner_name",
    dataIndex: "house_hold_owner_name",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Geo-Coordinates Tagged",
    key: "is_geo_coordinates_submitted",
    dataIndex: "is_geo_coordinates_submitted",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
];
export const wqmSec1Columns = [
  {
    title: "Secretariat code",
    key: "secretariat_code",
    dataIndex: "secretariat_code",
    sorter: (a, b) => a?.secretariat_code - b?.secretariat_code,
  },
  {
    title: "No. of Streets",
    key: "total_streets",
    dataIndex: "total_streets",
    sorter: (a, b) => a?.total_streets - b?.total_streets,
  },
  {
    title: "No. of Households to be inspected by WAS",
    key: "total_households",
    dataIndex: "total_households",
    sorter: (a, b) => a?.total_households - b?.total_households,
  },
  {
    title: "No. of Households  inspected by WAS",
    key: "total_households_completed_survey_fortnight1",
    dataIndex: "total_households_completed_survey_fortnight1",
    sorter: (a, b) =>
      a?.total_households_completed_survey_fortnight1 -
      b?.total_households_completed_survey_fortnight1,
  },
];
const wqfilterColumns = wqmSec1Columns?.filter(
  (cols) => cols?.key !== "total_households_completed_survey_fortnight1"
);
export const wqmSec2Columns = [
  ...wqfilterColumns,
  {
    title: "No. of Households  inspected by WAS",
    key: "total_households_completed_survey_fortnight2",
    dataIndex: "total_households_completed_survey_fortnight2",
    sorter: (a, b) =>
      a?.total_households_completed_survey_fortnight1 -
      b?.total_households_completed_survey_fortnight1,
  },
];
export const wqmHouseCols = [
  {
    title: "Municipal Tap available",
    key: "is_municipal_tab_existed",
    dataIndex: "is_municipal_tab_existed",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
  {
    title: "Connection Type",
    key: "connection_type",
    dataIndex: "connection_type",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Authorisation",
    key: "connection_authorization",
    dataIndex: "connection_authorization",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Inspection taken",
    key: "fortnight1_inspection_taken",
    dataIndex: "fortnight1_inspection_taken",
    render(value, record) {
      return {
        props: {
          style: { background: value ? "#ECFDF3" : "#FEF3F2" },
        },
        children: (
          <div>{value === true ? "Yes" : value === false ? "No" : "-"}</div>
        ),
      };
    },
  },
  {
    title: "Water Quantity Assessment Results",
    children: [
      {
        title: "Sufficient Water Supplied",
        key: ["fortnight1", "is_sufficient_water_supplied"],
        dataIndex: ["fortnight1", "is_sufficient_water_supplied"],
        render(value, record) {
          return {
            props: {
              style: { background: "#ECFDFF" },
            },
            children: (
              <div>{value === true ? "Yes" : value === false ? "No" : "-"}</div>
            ),
          };
        },
      },
      {
        title: "Supply Time (in minutes)",
        key: ["fortnight1", "supply_time"],
        dataIndex: ["fortnight1", "supply_time"],
        render(value, record) {
          return {
            props: {
              style: { background: "#ECFDFF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Amount of Water Supplied(in litres)",
        key: ["fortnight1", "amount_of_water"],
        dataIndex: ["fortnight1", "amount_of_water"],
        render(value, record) {
          return {
            props: {
              style: { background: "#ECFDFF" },
            },
            children: <div>{value ? value?.toFixed(2) : "-"}</div>,
          };
        },
      },
    ],
  },
  {
    title: "Water Quality Assessment Results",
    children: [
      {
        title: "Water quality parameters verified ",
        key: ["fortnight1", "water_quality_params_verified"],
        dataIndex: ["fortnight1", "water_quality_params_verified"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value === true ? "Yes" : "No"}</div>,
          };
        },
      },
      {
        title: "Colour",
        key: ["fortnight1", "color_of_water"],
        dataIndex: ["fortnight1", "color_of_water"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Odour",
        key: ["fortnight1", "odour_of_water"],
        dataIndex: ["fortnight1", "odour_of_water"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "PH Value",
        key: ["fortnight1", "ph_value"],
        dataIndex: ["fortnight1", "ph_value"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Turbidity(NTU units)",
        key: ["fortnight1", "turbidity"],
        dataIndex: ["fortnight1", "turbidity"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Residual chlorine content(RC) ( Units in ppm)",
        key: ["fortnight1", "residual_chlorine_content"],
        dataIndex: ["fortnight1", "residual_chlorine_content"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
    ],
  },
];
export const wqmHouse2Cols = [
  {
    title: "Municipal Tap available",
    key: "is_municipal_tab_existed",
    dataIndex: "is_municipal_tab_existed",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
  {
    title: "Connection Type",
    key: "connection_type",
    dataIndex: "connection_type",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Authorisation",
    key: "connection_authorization",
    dataIndex: "connection_authorization",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Inspection taken",
    key: "fortnight2_inspection_taken",
    dataIndex: "fortnight2_inspection_taken",
    render(value, record) {
      return {
        props: {
          style: { background: value ? "#ECFDF3" : "#FEF3F2" },
        },
        children: (
          <div>
            {value === true ? (
              <Typography style={{ color: "#079455" }}>Yes</Typography>
            ) : value === false ? (
              <Typography style={{ color: "#D92D20" }}>No</Typography>
            ) : (
              "-"
            )}
          </div>
        ),
      };
    },
  },
  {
    title: "Water Quantity Assessment Results",

    children: [
      {
        title: "Sufficient Water Supplied",
        key: ["fortnight2", "is_sufficient_water_supplied"],
        dataIndex: ["fortnight2", "is_sufficient_water_supplied"],
        // render: (value) =>
        //   value === true ? "Yes" : value === false ? "No" : "-",
        render(value, record) {
          return {
            props: {
              style: { background: "#ECFDFF" },
            },
            children: (
              <div>{value === true ? "Yes" : value === false ? "No" : "-"}</div>
            ),
          };
        },
      },
      {
        title: "Supply Time (in minutes)",
        key: ["fortnight2", "supply_time"],
        dataIndex: ["fortnight2", "supply_time"],
        render(value, record) {
          return {
            props: {
              style: { background: "#ECFDFF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Amount of Water Supplied(in litres)",
        key: ["fortnight2", "amount_of_water"],
        dataIndex: ["fortnight2", "amount_of_water"],
        render(value, record) {
          return {
            props: {
              style: { background: "#ECFDFF" },
            },
            children: <div>{value ? value?.toFixed(2) : "-"}</div>,
          };
        },
      },
    ],
  },
  {
    title: "Water Quality Assessment Results",
    children: [
      {
        title: "Water quality parameters verified ",
        key: ["fortnight2", "water_quality_params_verified"],
        dataIndex: ["fortnight2", "water_quality_params_verified"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value === true ? "Yes" : "No"}</div>,
          };
        },
      },
      {
        title: "Colour",
        key: ["fortnight2", "color_of_water"],
        dataIndex: ["fortnight2", "color_of_water"],

        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Odour",
        key: ["fortnight2", "odour_of_water"],
        dataIndex: ["fortnight2", "odour_of_water"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "PH Value",
        key: ["fortnight2", "ph_value"],
        dataIndex: ["fortnight2", "ph_value"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Turbidity(NTU units)",
        key: ["fortnight2", "turbidity"],
        dataIndex: ["fortnight2", "turbidity"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
      {
        title: "Residual chlorine content(RC) ( Units in ppm)",
        key: ["fortnight2", "residual_chlorine_content"],
        dataIndex: ["fortnight2", "residual_chlorine_content"],
        render(value, record) {
          return {
            props: {
              style: { background: "#FDF4FF" },
            },
            children: <div>{value ? value : "-"}</div>,
          };
        },
      },
    ],
  },
];
