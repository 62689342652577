import React from "react";
import houses from "../../../assets/images/houses.svg";
import ticket from "../../../assets/images/ticket.svg";
import resolved from "../../../assets/images/resolved.svg";
import inspection from "../../../assets/images/inspection.svg";
import { Card, Col, Row, Space, Statistic, Typography, Tag } from "antd";
const OverviewCards = ({ data }) => {
  const cardData = [
    {
      icon: houses,
      heading: "Total Number of Households",
      count: data?.total_households,
    },
    {
      icon: inspection,
      heading: "Total Number of Inspections Done Till Date",
      count: data?.total_inspected,
    },
    {
      icon: ticket,
      heading: "Total Number of Tickets Raised",
      count: data?.total_tickets_raised,
    },
    {
      icon: resolved,
      heading: "Total Number of Tickets Resolved",
      count: data?.total_tickets_resolved,
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      {cardData?.map((item) => (
        <Col span={6} style={{ textAlign: "center" }}>
          <Card style={{ border: "1px solid #98A2B3", borderRadius: "8px" }}>
            <Space direction="vertical" size="large">
              <img src={item?.icon} />
              <Typography style={{ fontWeight: 600, fonstSize: "18px" }}>
                {item?.heading}
              </Typography>
              <Statistic
                value={item?.count}
                style={{ fontSize: "36px", fontWeight: 600 }}
              />
            </Space>
          </Card>
        </Col>
      ))}
      <Col
        span={24}
        style={{ textAlign: "right", marginTop: "10px", marginBottom: "10px" }}
      >
        <Tag
          style={{
            cursor: "auto",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #727272 ",
          }}
        >
          Past 90 days data
        </Tag>
      </Col>
    </Row>
  );
};
export default OverviewCards;
