import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import FortnightTable from "./FortnightTable";
import { fetchWqmSurveyAPI } from "../../../../services/inspection.services";
import { Typography, DatePicker } from "antd";
import dayjs from "dayjs";
const { Title } = Typography;
const Fortnight = ({ date, setDate, month }) => {
  // const month = dayjs().format("YYYY-MM");
  const router = useParams();
  const location = useLocation();
  const [geoData, setGeoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  // const [date, setDate] = useState(month);
  const comp = Object.keys(params);

  useEffect(() => {
    setLoading(true);
    let param = {
      page: page,
      month: date,
    };
    if (comp?.includes("street")) {
      param.street = params?.street;
    }
    if (comp?.includes("secretariat")) {
      param.secretariat = params?.secretariat;
    }
    const queryString = new URLSearchParams(param);
    fetchWqmSurveyAPI(queryString)
      .then((res) => {
        setGeoData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, params?.secretariat, params?.street, router?.fortnight, date]);
  return (
    <>
      <FortnightTable
        data={geoData}
        loading={loading}
        page={page}
        setPage={setPage}
        comp={comp}
        params={params}
        setDate={setDate}
        date={date}
      />
    </>
  );
};
export default Fortnight;
