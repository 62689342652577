import { ConfigProvider } from "antd";
import "./App.less";
import ProjectRoutes from "./routes/Routes";
import { customTheme } from "./theme/themeConfig";

function App() {
  return (
    <ConfigProvider theme={customTheme}>
      <ProjectRoutes />
    </ConfigProvider>
  );
}

export default App;
