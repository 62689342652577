import React from "react";
import { Row, Col } from "antd";

const CustomActionProgress = ({ data }) => {
  const Progress_bar = ({ progress, height, bgcolor, pending }) => {
    const Parentdiv = {
      height: height,
      width: "90%",
      backgroundColor: "#F97066",
      borderRadius: 4,
      position: "relative",
      marginBottom: "10px",
    };

    const Childdiv = {
      height: "100%",
      width: `${progress}%`,
      backgroundColor: bgcolor,
      borderRadius: 4,
      textAlign: "center",
      position: "absolute",
      left: 0,
      top: 0,
    };

    const Remainingdiv = {
      height: "80%",
      width: `${pending}%`,
      borderRadius: 4,
      textAlign: "center",
      position: "absolute",
      right: 0,
      top: 0,
    };

    const progresstext = {
      padding: 10,
      color: "white",
      fontWeight: 600,
    };

    return (
      <div style={Parentdiv}>
        <div style={Childdiv}>
          {progress !== 0 && (
            <span style={progresstext}>{`${progress.toFixed(0)}%`}</span>
          )}
        </div>
        <div style={Remainingdiv}>
          <span style={progresstext}>{`${pending.toFixed(0)}%`}</span>
        </div>
      </div>
    );
  };

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 350,
        overflow: "auto",
        padding: "0 16px",
      }}
    >
      {data?.map((item) => {
        return (
          <div>
            <Row>
              <Col span={20}>
                <text style={{ fontWeight: 600, fontSize: "16px" }}>
                  {item?.ticket_title}
                </text>
              </Col>
              <Col span={4}>
                <text style={{ fontWeight: 600, fontSize: "16px" }}>
                  {item?.tickets_resolved} / {item?.total_tickets}
                </text>
              </Col>
            </Row>

            <Progress_bar
              bgcolor="#2563EB"
              progress={
                (item?.tickets_resolved /
                  (item?.tickets_resolved + item?.tickets_pending)) *
                100
              }
              pending={
                (item?.tickets_pending /
                  (item?.tickets_resolved + item?.tickets_pending)) *
                100
              }
              height={20}
            />
          </div>
        );
      })}
    </div>
  );
};
export default CustomActionProgress;
