import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { Col } from "antd";
import dayjs from "dayjs";

const GrievancesLineChart = ({ data }) => {
  const formatMonth = (date) => {
    return dayjs(date).format("MMM");
  };
  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload);
    if (active && payload && payload.length) {
      return (
        <div className="line-tooltip">
          <p
            style={{
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "20px",
            }}
          >{` ${dayjs?.(payload[0].payload?.created_at__date).format(
            "MMM D-YYYY"
          )}`}</p>
          <div>
            <p
              style={{
                fontWeight: "600",
                fontSize: "22px",
                lineHeight: "20px",
              }}
            >{`${payload[0].payload?.pending} `}</p>
          </div>
        </div>
      );
    }

    return null;
  };
  const final_data = [];
  let prevMonth = "";

  data?.forEach((item, index) => {
    const currentMonth = formatMonth(item?.created_at__date);
    if (currentMonth !== prevMonth) {
      final_data.push({
        ...item,
        month: currentMonth,
      });

      prevMonth = currentMonth;
    } else {
      final_data.push({
        ...item,
        month: "",
      });
    }
  });

  const customTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          // textAnchor={payload?.index === 0 ? "end" : "center"}
          textAnchor="end"
          fill="#000000"
          style={{ fontWeight: "600" }}
          // transform="rotate(-35)"
        >
          {payload?.value}
        </text>
      </g>
    );
  };
  return (
    <Col className="graph-card">
      <ResponsiveContainer width="100%" height={350}>
        <LineChart width={600} height={300} data={final_data}>
          <XAxis
            dataKey="month"
            tick={customTick}
            tickLine={false}
            interval={0}
            axisLine={false}
          />
          <YAxis tickLine={false} axisLine={false} />
          <CartesianGrid vertical={false} />
          <Tooltip content={CustomTooltip} />
          <Line
            type="monotone"
            dataKey="pending"
            name="Content"
            stroke="#363062"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
      {/* <div
        style={{
          background: "#E3DFFF",
          padding: "4px",
          border: "1px solid #473F81",
        }}
      >
        <p>Grievances: {date}</p>
        <p>Date: {score}</p>
      </div> */}
    </Col>
  );
};

export default GrievancesLineChart;
