import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const InspectionsBarChart = ({ data }) => {
  const monthTickFormatter = (tick) => {
    return typeof tick === "string" ? tick.slice(0, tick.indexOf(" ")) : "";
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="qc-tooltip">
          <p className="tooltip">{`Fortnight 1 Taken: ${payload[0].payload?.fortnight1_total_inspections_taken}`}</p>
          <p className="tooltip">{`Fortnight 1 To be taken: ${payload[0].payload?.fortnight1_total_inspections_to_be_taken}`}</p>

          <p className="tooltip">{`Fortnight 2 Taken: ${payload[0].payload?.fortnight2_total_inspections_taken}`}</p>
          <p className="tooltip">{`Fortnight 2 To be taken: ${payload[0].payload?.fortnight2_total_inspections_to_be_taken}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        barSize={30}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 30,
          bottom: 20,
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={monthTickFormatter}
          // axisLine={false}
          tickLine={false}
          // tick={false}
        />

        <CartesianGrid vertical={false} />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip content={CustomTooltip} cursor={{ fill: "transparent" }} />
        <Bar
          dataKey="fortnight1_total_inspections_taken"
          stackId="a"
          fill="#2563EB"
        />
        <Bar
          dataKey="fortnight1_total_inspections_to_be_taken"
          stackId="a"
          fill="#F97066"
          radius={[6, 6, 0, 0]}
        />
        <Bar
          dataKey="fortnight2_total_inspections_taken"
          stackId="b"
          fill="#2563EB"
        />
        <Bar
          dataKey="fortnight2_total_inspections_to_be_taken"
          stackId="b"
          fill="#F97066"
          radius={[6, 6, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default InspectionsBarChart;
