import React, { useState } from "react";
import { Col, Menu, Row, DatePicker } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ticketItems } from "../../../constants/commonItems";
import WaterQualityTickets from "./WaterQualityTickets";
import dayjs from "dayjs";
import WaterLeakage from "./WaterLeakage";

const { RangePicker } = DatePicker;
const TicketTabs = () => {
  const yesterday = dayjs().subtract(15, "day");
  const today = dayjs();
  const query = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState({
    start_date: yesterday,
    end_date: today,
  });

  const onMenuClick = (e) => {
    const selectedTab = e.key;
    navigate(`/dashboard/${query?.tab}/${selectedTab}`);
    // setDate({ start_date: today, end_date: tomorrow });
  };
  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={20}>
          <Menu
            items={ticketItems}
            mode="horizontal"
            onClick={onMenuClick}
            selectedKeys={query?.inspectiontab}
          />
        </Col>
        <Col span={4}>
          <RangePicker
            allowClear={false}
            style={{ background: "#fff" }}
            disabledDate={disabledDate}
            defaultValue={[
              dayjs(date.start_date.format("YYYY-MM-DD")),
              dayjs(date.end_date.format("YYYY-MM-DD")),
            ]}
            onChange={(dateString) => {
              setDate({
                ...date,
                start_date: dateString[0],
                end_date: dateString[1],
              });
            }}
          />
        </Col>
        <Col span={24}>
          {query?.inspectiontab === "water_quantity_quality" && (
            <WaterQualityTickets date={date} />
          )}
          {query?.inspectiontab === "water_leakage" && (
            <WaterLeakage date={date} />
          )}
        </Col>
      </Row>
    </>
  );
};
export default TicketTabs;
