import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import InspectionTabs from "../components/Dashboard/Inspections/InspectionTabs";
import WqmTabs from "../components/Dashboard/Inspections/Wqm/WqmTabs";
import DeleteAccount from "../components/DeleteAccount/DeleteAccount";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import SetPassword from "../components/ForgotPassword/SetPassword";
import Login from "../components/Login/Login";
import Logout from "../Logout/Logout";
import PrivateRoutes from "./PrivateRoutes";
import ExcelUploadPage from "../components/WasPages/ExcelUploadPage";
const ProjectRoutes = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/SetPassword" element={<SetPassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard/:tab" element={<Dashboard />}>
              <Route path=":inspectiontab" element={<InspectionTabs />}>
                <Route path=":fortnight" element={<WqmTabs />}></Route>
              </Route>
            </Route>
            <Route path="was/home" element={<ExcelUploadPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};
export default ProjectRoutes;
