import React, { useState } from "react";
import { Col, Mentions, Menu, Row, Radio, DatePicker, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { fortnightItems } from "../../../../constants/commonItems";
import Fortnight from "./Fortnight";
import dayjs from "dayjs";
const { Title } = Typography;
const WqmTabs = () => {
  const month = dayjs().format("YYYY-MM");
  const query = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState(month);
  const onMenuClick = (e) => {
    const selectedTab = e.target.value;

    navigate(`/dashboard/${query?.tab}/wqm/${selectedTab}`);
  };
  const disabledDate = (current) => {
    // Disable dates in the future (after the current month)
    return current && current > dayjs().endOf("month");
  };
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={20}>
          <Radio.Group
            // style={{ marginBottom: "10px" }}
            options={fortnightItems}
            onChange={onMenuClick}
            value={query?.fortnight}
            className="dashboard-menu"
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
        <Col span={4}>
          <div style={{ textAlign: "right" }}>
            {/* <Title level={5}>Year - Month</Title> */}
            <DatePicker
              allowClear={false}
              picker="month"
              disabledDate={disabledDate}
              defaultValue={dayjs(month)}
              onChange={(_, dateString) => {
                setDate(dateString);
              }}
            />
          </div>
        </Col>
        <Col span={24}>
          <Fortnight setDate={setDate} date={date} month={month} />
        </Col>
      </Row>
    </>
  );
};
export default WqmTabs;
