import { getItem, clear } from "./localStorage";
import { ACCESS_TOKEN, USER } from "../constants/defaultKeys";

export const isAuthenticated = () => {
  let isTokenAvailable = false;
  const access_token = getItem(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
};

export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};
