import React, { Fragment } from "react";
import "./Layout.css";
import { Col, Layout, Row, Card } from "antd";
import Toolbar from "../Toolbar/Toolbar";
// import Toolbar from "../components/Toolbar/Toolbar";
const { Header, Content, Footer } = Layout;

function DefaultLayout({ children }) {
  return (
    <Fragment>
      <Layout className="layout">
        <Header className="layout-header">
          <Toolbar />
        </Header>

        <Content className="layout-body">
          <Row>
            <Col span={22} offset={1}>
              {children}
            </Col>
          </Row>
        </Content>

        <Footer className="footer">
          Copyright © 2024 Nellore Municipal Corporation. Developed By
          <a
            href="https://beyondscale.tech/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "underline",
              paddingLeft: "5px",
            }}
          >
            BeyondScale.
          </a>
          <br />
        </Footer>
      </Layout>
    </Fragment>
  );
}

export default DefaultLayout;
