import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { fetchMapAPI } from "../../../services/geo.services";
import { Skeleton } from "antd";
import SecretariatModal from "./SecretariatModal";
import icon from "../../../assets/images/dot.svg";
import { useParams } from "react-router-dom";
const MapView = ({ date }) => {
  const router = useParams();
  const [heatmapData, setHeatmapData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [bubbleClick, setBubbleClick] = useState(null);

  function circleClick(e) {
    console.log(e);
    setBubbleClick(e);
    setVisible(true);
  }

  useEffect(() => {
    setLoading(true);
    fetchMapAPI(
      `${router?.inspectiontab}&start_date=${date?.start_date?.format(
        "YYYY-MM-DD"
      )}&end_date=${date?.end_date?.format("YYYY-MM-DD")}`
    )
      .then((res) => {
        setHeatmapData(
          res?.data?.filter(
            (item) => item?.latitude !== 0 && item?.longitude !== 0
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [router?.inspectiontab, date]);
  // const handleBoundsChange = (center, zoom, bounds, marginBounds) => {
  //   console.log(center);
  // };
  const googleMapCircle = (map, maps) => {
    heatmapData.map((cords) => {
      let zoom_factor = 50;
      let scale_factor = 10 * zoom_factor;
      let min_radius = 1 * zoom_factor;

      const Circle = new maps.Circle({
        strokeColor: "#DC143C",
        fillColor: "#DC143C",
        fillOpacity: 0.2,
        map: map,

        center: { lat: cords?.lat, lng: cords?.long },
        // radius: 100,
        radius:
          cords?.bubble_size_density * scale_factor * cords?.tickets_count <
          min_radius
            ? min_radius
            : cords.bubble_size_density * zoom_factor,
      });
      const infoWindow = new maps.InfoWindow({
        content: `<div style="font-weight: 700;font-size:20px;margin:10px 0 10px 0">${
          router?.inspectiontab === "water_quantity"
            ? "Water Supply Issues"
            : "Water Quality Issues"
        }</div>
        <div style="margin:5px 0 5px 0">Secretariat Number: ${
          cords.secretariat_number
        }</div>
        <div style="margin:5px 0 5px 0">Secretariat Name: ${
          cords.secretariat_name
        }</div>
        <div style="margin:5px 0 5px 0">Total Households: ${
          cords.total_households
        }</div>
        <div style="margin:5px 0 5px 0">Households inspected: ${
          cords.total_households_inspected
        }</div>
       ${
         router?.inspectiontab === "water_quantity"
           ? `<div style="margin:5px 0 5px 0">Total HHs with Supply Issue: ${cords.number_of_water_supply_issues}</div>`
           : `<div style="margin:5px 0 5px 0">Total HHs with Colour Issue: ${cords.number_of_color_issues}</div>
           <div style="margin:5px 0 5px 0">Total HHs with Odour Issue: ${cords.number_of_odour_issues}</div>
           <div style="margin:5px 0 5px 0">Total HHs with Chlorine Issue: ${cords.number_of_chlorine_content_issues}</div>`
       } `,
      });

      maps.event.addListener(Circle, "click", function (event) {
        infoWindow.setPosition(event.latLng);
        infoWindow.open(map);
      });

      maps.event.addListener(infoWindow, "closeclick", function () {
        infoWindow.close();
      });
    });
  };
  const googleMapMarker = (map, maps) => {
    heatmapData.map((cords) => {
      const Marker = new maps.Marker({
        map: map,

        position: { lat: cords?.lat, lng: cords?.long },
      });
      const infoWindow = new maps.InfoWindow({
        content: `<div style="font-weight: 700;font-size:20px;margin:10px 0 10px 0">Water Leakage Issues</div>
           <div style="margin:5px 0 5px 0">Secretariat no: ${cords.secretariat_number}</div>
        <div style="margin:5px 0 5px 0">Secretariat name: ${cords.secretariat_name}</div>
        <div style="margin:5px 0 5px 0">Total Leakages Reported: ${cords.tickets_count}</div>
        <div style="margin:5px 0 5px 0">Total Leakages Resolved: ${cords.tickets_resolved}</div>`,
      });

      maps.event.addListener(Marker, "click", function (event) {
        infoWindow.setPosition(event.latLng);
        infoWindow.open(map);
      });

      maps.event.addListener(infoWindow, "closeclick", function () {
        infoWindow.close();
      });
    });
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      {loading ? (
        <Skeleton active />
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: ["visualization"],
          }}
          defaultCenter={{ lat: 14.440521240436638, lng: 79.97216872475161 }}
          // onBoundsChange={handleBoundsChange}
          defaultZoom={13}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            router?.inspectiontab === "water_leakage"
              ? googleMapMarker(map, maps)
              : googleMapCircle(map, maps);
          }}
        />
      )}
      {visible && (
        <SecretariatModal
          bubbleClick={bubbleClick}
          setVisible={setVisible}
          visible={visible}
        />
      )}
    </div>
  );
};
export default MapView;
// var kml = new maps.KmlLayer({
//   url: "https://drive.google.com/uc?export=download&id=1EhM4QRfc_nRpJgFyEUNeGhWxkY3D_vh7",
// });
// var kml = new maps.KmlLayer({
//   url: "https://drive.google.com/uc?export=download&id=1bCBH784phTY_wK0WZiAbbDRlXb1dJsv7",
// });
// kml.setMap(map);
// const heatmapLayer = new maps.visualization.HeatmapLayer({
//   data: heatmapData.map(
//     (point) => new maps.LatLng(point?.latitude, point?.longitude)
//   ),
//   map: map,
//   radius: 20,
//   opacity: 0.6,
// });
// heatmapLayer.setMap(map);
// var kml = new maps.KmlLayer({
//   url: "https://drive.google.com/uc?export=download&id=1EhM4QRfc_nRpJgFyEUNeGhWxkY3D_vh7",
// });
// kml.setMap(map);
