import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const QcBarGraph = ({ data }) => {
  const month = (value) => {
    let dateString = value;
    let dateObj = new Date(dateString);
    var monthName = dateObj.toLocaleString("en-US", { month: "short" });

    return monthName;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="qc-tooltip">
          <p
            style={{
              fontWeight: "600",
              fontSize: "16px",
            }}
          >{` ${month(payload[0].payload?.month)}`}</p>
          <p
            style={{
              fontWeight: "600",
              fontSize: "16px",
            }}
          >{`Verified: ${payload[0].payload?.verified}`}</p>
          <p
            style={{
              fontWeight: "600",
              fontSize: "16px",
            }}
          >{`Pending: ${payload[0].payload?.pending}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        barSize={30}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <XAxis
          dataKey="month"
          tickFormatter={month}
          axisLine={false}
          tickLine={false}
        />

        <CartesianGrid vertical={false} />
        <YAxis axisLine={false} tickLine={false} interval={1} />
        <Tooltip content={CustomTooltip} cursor={{ fill: "transparent" }} />
        <Bar dataKey="pending" stackId="a" fill="#F97066" />
        <Bar
          dataKey="verified"
          stackId="a"
          fill="#2563EB"
          radius={[6, 6, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default QcBarGraph;
