import { Fragment, useState } from "react";
import { Form, Input, Button, Row, Col, message, Typography, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from "../../assets/images/loginlogo.svg";
import { forgotPasswordAPI } from "../../services/auth.services";

const { Paragraph } = Typography;

const ForgotPassword = () => {
  const [forgotMessageCard, setForgotMessageCard] = useState(false);
  const [username, setUsername] = useState(null);

  const onFinish = (values) => {
    let data = {
      ...values,
      app_name: "wqm",
    };
    forgotPasswordAPI(data)
      .then((res) => {
        message.success(res.data.message);
        // setForgotMessageCard(true);
        // setUsername(values?.email);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };
  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <Card bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <img src={logo} alt="check logo" height="103px" />
            </div>
            {forgotMessageCard ? (
              <>
                <Paragraph>
                  We've sent a reset password email to {username && username}.
                  Please click the reset password link in the email to set your
                  new password.
                </Paragraph>
                <Paragraph>Didn't receive the email yet?</Paragraph>
                <Paragraph>
                  Please check your spam folder, or{" "}
                  <span
                    className="footer-nav-links"
                    onClick={() => setForgotMessageCard(false)}
                  >
                    try again
                  </span>
                </Paragraph>
              </>
            ) : (
              <>
                <Form name="login" onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                    help="We'll email you the link to reset your password."
                  >
                    <Input
                      autoFocus={true}
                      size="large"
                      type="email"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                    />
                  </Form.Item>

                  <Form.Item style={{ float: "right" }}></Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      className="login-form-button"
                    >
                      Request reset link
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{ textAlign: "center" }}>
                  <Link to="/" className="footer-links">
                    Back to login
                  </Link>
                </div>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default ForgotPassword;
