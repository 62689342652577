import React, { useEffect, useState } from "react";
import { Row, Col, Pagination, Breadcrumb, Space, Tag } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { fetchWlmTicketsAPI } from "../../../services/inspection.services";
import WaterLeakageTable from "./WaterLeakageTable";

const WaterLeakage = ({ date }) => {
  const query = useParams();
  const location = useLocation();
  const [ticketsData, setTicketsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const comp = Object.keys(params);

  useEffect(() => {
    let param = {
      page: page,
      start_date: date?.start_date?.format("YYYY-MM-DD"),
      end_date: date?.end_date?.format("YYYY-MM-DD"),
    };

    if (comp?.includes("secretariat")) {
      param.secretariat = params?.secretariat;
    }
    const queryString = new URLSearchParams(param);
    setLoading(true);
    fetchWlmTicketsAPI(queryString)
      .then((res) => {
        setTicketsData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, date, params?.secretariat]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Space>
          <Tag color="blue">
            Total Raised :{" "}
            <span style={{ fontWeight: "bold" }}>
              {ticketsData?.tickets?.total_raised}
            </span>
          </Tag>
          <Tag color="blue">
            Total Pending :{" "}
            <span style={{ fontWeight: "bold" }}>
              {ticketsData?.tickets?.total_pending}
            </span>
          </Tag>
          <Tag color="blue">
            Total Resolved :{" "}
            <span style={{ fontWeight: "bold" }}>
              {ticketsData?.tickets?.total_resolved}
            </span>
          </Tag>
          <Tag color="blue">
            Total Verified :{" "}
            <span style={{ fontWeight: "bold" }}>
              {ticketsData?.tickets?.total_verified}
            </span>
          </Tag>
        </Space>
      </Col>
      <Col span={24}>
        {comp?.includes("secretariat") && (
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              href={`/dashboard/${query?.tab}/${query?.inspectiontab}`}
            >
              <Space>
                {params?.secretariat_name}
                <Tag>{params?.number}</Tag>
              </Space>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
      </Col>
      <Col span={24}>
        <WaterLeakageTable
          data={ticketsData}
          loading={loading}
          params={params}
          comp={comp}
        />
      </Col>
      <Col span={24} style={{ textAlign: "right" }}>
        <Pagination
          current={page}
          pageSize={30}
          total={ticketsData?.total_count}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col>
    </Row>
  );
};
export default WaterLeakage;
