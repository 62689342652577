import React from "react";
import { Space, Typography, Avatar } from "antd";
import { useParams } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import PieLegend from "./PieLegend";
// import PieLegend from "./PieLegend";
const { Text } = Typography;
const ActionsPieChart = ({ data }) => {
  const query = useParams();
  const COLORS = ["#FFBB28", "#4C8BFF", "#00F89A", "#281EFF"];
  const pieData = [
    { name: "Pending", count: data?.pending },
    { name: "Resolved", count: data?.resolved },
  ];
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="count"
          >
            {pieData?.map((item, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  item?.name === "Resolved"
                    ? "#2563EB"
                    : item?.name === "Pending" && "#F97066"
                }
              />
            ))}
          </Pie>
          {/* <Legend /> */}
        </PieChart>
      </ResponsiveContainer>
      <PieLegend data={pieData} />
    </>
  );
};
export default ActionsPieChart;
