import React from "react";
import { Modal, List, Row, Col, Tag, Space } from "antd";

import { Link } from "react-router-dom";

function SecretariatModal({ visible, setVisible, bubbleClick, issue }) {
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      width={1100}
      title={`  ${bubbleClick?.name} `}
    >
      <>Hello</>
    </Modal>
  );
}

export default SecretariatModal;
