import { Typography } from "antd";
import { Link } from "react-router-dom";

export const secTicketColumns = [
  {
    title: "Secretariat code",
    key: "secretariat_code",
    dataIndex: "secretariat_code",
    sorter: (a, b) => a?.secretariat_code - b?.secretariat_code,
  },
  {
    title: "No. of HHs",
    key: "total_households",
    dataIndex: "total_households",
    sorter: (a, b) => a?.total_households - b?.total_households,
  },
  {
    title: "No. of HHs Inspected",
    key: "total_households_completed_survey",
    dataIndex: "total_households_completed_survey",
    sorter: (a, b) =>
      a?.total_households_completed_survey -
      b?.total_households_completed_survey,
  },
  {
    title: "No. of HHs with Water Supply Issue",
    key: "total_households_with_water_supply_issue",
    dataIndex: "total_households_with_water_supply_issue",
    sorter: (a, b) =>
      a?.total_households_with_water_supply_issue -
      b?.total_households_with_water_supply_issue,
  },
  {
    title: "No. of HHs with Water Colour Issue",
    key: "total_households_with_water_color_issue",
    dataIndex: "total_households_with_water_color_issue",
    sorter: (a, b) =>
      a?.total_households_with_water_color_issue -
      b?.total_households_with_water_color_issue,
  },
  {
    title: "No. of HHs with Water Odour Issue",
    key: "total_households_with_water_odour_issue",
    dataIndex: "total_households_with_water_odour_issue",
    sorter: (a, b) =>
      a?.total_households_with_water_odour_issue -
      b?.total_households_with_water_odour_issue,
  },
  {
    title: "No. of HHs with Chlorine Content Issue",
    key: "total_households_with_chlorine_content_issue",
    dataIndex: "total_households_with_chlorine_content_issue",
    sorter: (a, b) =>
      a?.total_households_with_chlorine_content_issue -
      b?.total_households_with_chlorine_content_issue,
  },
];
export const houseTicketColumns = [
  {
    title: "HH Door Number",
    key: "door_no",
    dataIndex: "door_no",
  },
  {
    title: "HH Owner Name",
    key: "house_hold_owner_name",
    dataIndex: "house_hold_owner_name",
  },
  {
    title: "Inspection done",
    key: "inspection_taken",
    dataIndex: "inspection_taken",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
  {
    title: "Water Supply Issue",
    key: "water_supply_issue",
    dataIndex: "water_supply_issue",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
  {
    title: "Water Colour Issue",
    key: "water_color_issue",
    dataIndex: "water_color_issue",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
  {
    title: "Water Odour Issue",
    key: "water_odour_issue",
    dataIndex: "water_odour_issue",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
  {
    title: "Chorine Content Issue",
    key: "chlorine_content_issue",
    dataIndex: "chlorine_content_issue",
    render: (value) =>
      value === true ? (
        <Typography style={{ color: "#079455" }}>Yes</Typography>
      ) : value === false ? (
        <Typography style={{ color: "#D92D20" }}>No</Typography>
      ) : (
        "-"
      ),
  },
];

export const wlmTicketColumns = [
  {
    title: "Total Tickets",
    key: "total_tickets_reported",
    dataIndex: "total_tickets_reported",
  },
  {
    title: "Tickets resolved",
    key: "total_tickets_resolved",
    dataIndex: "total_tickets_resolved",
  },
];
export const secWlmTicketColumns = [
  {
    title: "Ticket ID",
    key: "ticket_uid",
    dataIndex: "ticket_uid",
  },
  {
    title: "Land Mark close to Leakage",
    key: "landmark",
    dataIndex: "landmark",
  },
  {
    title: "Status",
    key: "ticket_status",
    dataIndex: "ticket_status",
  },
  {
    title: "Remarks",
    key: "resolution_remarks",
    dataIndex: "resolution_remarks",
  },
  {
    title: "Image",
    key: "resolution_image",
    dataIndex: "resolution_image",
    render: (value) => (
      <Link to={value} target="_blank">
        View image
      </Link>
    ),
  },
];
