import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../helpers/utility";
import Layout from "../layout/Layout";

const PrivateRoute = () => {
  return isAuthenticated() ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
