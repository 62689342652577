import React, { useState } from "react";
import { Menu, Row, Col, DatePicker } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { mapMenu } from "../../../constants/commonItems";
import MapView from "./MapView";
import dayjs from "dayjs";

// import WqmTabs from "./Wqm/WqmTabs";
const { RangePicker } = DatePicker;
const MapTabs = () => {
  const yesterday = dayjs().subtract(15, "day");
  const today = dayjs();
  const navigate = useNavigate();
  const router = useParams();
  const [date, setDate] = useState({
    start_date: yesterday,
    end_date: today,
  });
  const onClick = (e) => {
    navigate(`/dashboard/${router?.tab}/${e.key}`);
  };
  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }
  return (
    <Row gutter={[12, 12]}>
      <Col span={20}>
        <Menu
          items={mapMenu}
          mode="horizontal"
          onClick={onClick}
          selectedKeys={router?.inspectiontab}
        />
      </Col>
      <Col span={4}>
        <RangePicker
          disabledDate={disabledDate}
          allowClear={false}
          style={{ background: "#fff" }}
          defaultValue={[
            dayjs(date.start_date.format("YYYY-MM-DD")),
            dayjs(date.end_date.format("YYYY-MM-DD")),
          ]}
          onChange={(dateString) => {
            setDate({
              ...date,
              start_date: dateString[0],
              end_date: dateString[1],
            });
          }}
        />
      </Col>
      <MapView date={date} />
    </Row>
  );
};
export default MapTabs;
