import React, { useEffect, useState } from "react";
import { Card, Col, Row, Typography, Form, Input, Button, message } from "antd";
import logo from "../../assets/images/loginlogo.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { loginAPI } from "../../services/auth.services";
import JSEncrypt from "jsencrypt";
import {
  ACCESS_TOKEN,
  publicKey,
  REFRESH_TOKEN,
  USER,
} from "../../constants/defaultKeys";
import { getItem, setItem } from "../../helpers/localStorage";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const userData = JSON.parse(getItem(USER));

  useEffect(() => {
    if (userData) {
      navigateUser();
    }
  }, [userData]);
  const navigateUser = () => {
    if (
      userData?.is_deputy_executive_engineer ||
      userData?.is_executive_engineer ||
      userData?.is_ae ||
      userData?.is_superintending_engg ||
      userData?.is_super_user
    ) {
      navigate(`dashboard/overview`);
    } else if (userData?.is_was) {
      navigate(`was/home`);
    }
  };
  const onSubmit = (values) => {
    setLoading(true);
    loginAPI({ ...values, password: encrypt.encrypt(values.password) })
      .then((res) => {
        let userData = res?.data;
        setItem(ACCESS_TOKEN, userData.access_token);
        setItem(REFRESH_TOKEN, userData.refresh_token);
        setItem(USER, JSON.stringify(userData));
        if (userData?.is_mp) {
          message.error(
            "Unauthorized access. Please contact support for assistance."
          );
        }
        navigateUser();
        setLoading(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <Row justify="center" align="middle" className="login">
      <Col>
        <Card style={{ borderRadius: "12px" }}>
          <Row>
            <img src={logo} height="103px" />
          </Row>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 28,
              // color: "#1E3A8A",
              textAlign: "center",
            }}
          >
            Login
          </Typography>
          <Form name="login" layout="vertical" onFinish={onSubmit}>
            <Form.Item
              required={false}
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username",
                },
              ]}
            >
              <Input autoFocus={true} size="large" placeholder="Username" />
            </Form.Item>
            <Form.Item
              required={false}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <div style={{ paddingBottom: "10px" }}>
              <Link to="/forgot-password" className="footer-links">
                Forgot password?
              </Link>
            </div>

            <Form.Item className="login-btn">
              <Button
                block
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                className="login-form-button"
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Typography
          style={{ color: "#fff", textAlign: "center", marginTop: "10px" }}
        >
          Copyright © 2024 Nellore Municipal Corporation. Developed By
          <a
            href="https://beyondscale.tech/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "underline",
              paddingLeft: "5px",
            }}
          >
            BeyondScale.
          </a>
          <br />
        </Typography>
      </Col>
    </Row>
  );
};
export default Login;
