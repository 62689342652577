import axiosInstance from "../config/axios.config";

export function loginAPI(payload) {
  return axiosInstance.post("accounts/user-login/", payload);
}
export function forgotPasswordAPI(payload) {
  return axiosInstance.post("/accounts/reset-password/", payload);
}
export function setPasswordAPI(payload) {
  return axiosInstance.post("/accounts/set-password/", payload);
}
