import { Col, Row, Space } from "antd";
import UserAvatar from "./UseAvatar";
import { HomeOutlined } from "@ant-design/icons";
import { USER, colors } from "../constants/defaultKeys";
import { getItem } from "../helpers/localStorage";
import Logo from "../Logo/Logo";
import "./Toolbar.css";
import { useNavigate } from "react-router-dom";

function Toolbar() {
  const navigate = useNavigate();
  const userDetails = JSON.parse(getItem(USER));

  const navigateUser = () => {
    if (
      userDetails?.is_deputy_executive_engineer ||
      userDetails?.is_executive_engineer ||
      userDetails?.is_ae ||
      userDetails?.is_superintending_engg ||
      userDetails?.is_super_user
    ) {
      navigate(`dashboard/overview`);
    } else if (userDetails?.is_was) {
      navigate(`was/home`);
    }
  };
  return (
    <Row>
      <Col span={12} className="grid-container">
        <div className="menu-logo" onClick={navigateUser}>
          <Logo />
        </div>
      </Col>

      <Col span={6} className="home">
        <Space className="gap-16">
          <span style={{ color: "#1E3A8A" }} onClick={navigateUser}>
            <HomeOutlined style={{ color: "#1E3A8A" }} /> Home
          </span>
        </Space>
      </Col>
      <Col span={6} style={{ marginBottom: "0.3rem" }}>
        <div span={1} style={{ textAlign: "right" }}>
          <UserAvatar user={userDetails} colors={colors} />
        </div>
      </Col>
    </Row>
  );
}

export default Toolbar;
