export const menuItems = [
  { label: "Overview", value: "overview" },
  { label: "HH Geo-Tagging", value: "geo" },
  { label: "Inspections", value: "inspections" },
  { label: "Tickets", value: "tickets" },
  { label: "Map-view", value: "map" },
];
export const inspectionMenu = [
  { label: "Water Quality & Quantity Management", key: "wqm" },
  // { label: "Water Leak Management", key: "wlm" },
  // { label: "Scour Valve Operations", key: "scour" },
];
export const mapMenu = [
  { label: "Water Quantity", key: "water_quantity" },
  { label: "Water Quality", key: "water_quality" },
  { label: "Water leakage", key: "water_leakage" },
];
export const fortnightItems = [
  { label: "Fortnight-1", value: "fortnight-1" },
  { label: "Fortnight-2", value: "fortnight-2" },
];
export const ticketItems = [
  { label: "Water Quality & Quantity ", key: "water_quantity_quality" },
  { label: "Water Leakage", key: "water_leakage" },
];
