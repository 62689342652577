import React, { useState, useEffect } from "react";
import GeoTaggingTable from "./GeoTaggingTable";
import {
  downloadGeoTaggingReport,
  fetchGeoTaggingAPI,
} from "../../../services/geo.services";
import { useLocation } from "react-router-dom";
import { Button, Col, Row, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
const GeoTagging = () => {
  const location = useLocation();
  const [geoData, setGeoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const comp = Object.keys(params);
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    setLoading(true);
    let param = {};
    if (comp?.includes("door")) {
      param.household = params?.door;
    }
    if (comp?.includes("street")) {
      param.street = params?.street;
    }
    if (comp?.includes("secretariat")) {
      param.secretariat = params?.secretariat;
    }
    const queryString = new URLSearchParams(param);
    fetchGeoTaggingAPI(queryString)
      .then((res) => {
        setGeoData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.secretariat, params?.street, params?.door]);

  const handleDownload = () => {
    setDownloadLoading(true);
    downloadGeoTaggingReport()
      .then((res) => {
        let blob = res?.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "geo_tagging_report.xlsx";
        a.click();
        setDownloadLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, Please try after sometime");
        setDownloadLoading(false);
      });
  };

  return (
    <Row gutter={[0, 4]}>
      {(userDetails?.is_deputy_executive_engineer ||
        userDetails?.is_executive_engineer ||
        userDetails?.is_superintending_engg) &&
        !params?.secretariat && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              ghost
              icon={<DownloadOutlined />}
              loading={downloadLoading}
              onClick={handleDownload}
            >
              Download Results
            </Button>
          </Col>
        )}
      <Col span={24}>
        <GeoTaggingTable
          data={geoData}
          loading={loading}
          // page={page}
          // setPage={setPage}
          comp={comp}
          params={params}
        />
      </Col>
    </Row>
  );
};
export default GeoTagging;
