import { Button, Card, Descriptions, Modal } from "antd";
import Typography from "antd/es/typography/Typography";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const HouseDataModal = ({ isVisible, setIsVisible, params, data }) => {
  const query = useParams();
  const navigate = useNavigate();
  return (
    <Modal
      title={
        <>
          <Typography
            style={{ textAlign: "center", fontSize: "20px", fontWeight: 700 }}
          >
            Water connection Details
          </Typography>
          <Typography
            style={{ textAlign: "center", fontSize: "16px", fontWeight: 400 }}
          >
            Door number :{" "}
            <span style={{ color: "#1B3799" }}>{data?.door_no}</span>
          </Typography>
        </>
      }
      open={isVisible || params?.door}
      onCancel={() => {
        setIsVisible(false);
        navigate(
          `/dashboard/${query?.tab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}&street_name=${params?.street_name}&street=${params?.street}`
        );
      }}
      footer={
        <Button
          type="primary"
          onClick={() => {
            navigate(
              `/dashboard/${query?.tab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}&street_name=${params?.street_name}&street=${params?.street}`
            );
            setIsVisible(false);
          }}
        >
          Ok
        </Button>
      }
    >
      <Card>
        <Descriptions column={1} labelStyle={{ color: "black" }}>
          <Descriptions.Item label="Owner Name">
            {data?.house_hold_owner_name ? data?.house_hold_owner_name : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile Number">
            {data?.house_hold_owner_contact
              ? data?.house_hold_owner_contact
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Property tax Assessment">
            {data?.property_tax_assesment_no
              ? data?.property_tax_assesment_no
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Property type">
            {data?.property_type ? data?.property_type : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="No of Floors">
            {data?.total_floors ? data?.total_floors : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="No of Flats">
            {data?.total_flats ? data?.total_flats : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Property Tax assesment no">
            {data?.property_tax_assesment_no
              ? data?.property_tax_assesment_no
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Available Municipal Tap">
            {data?.is_municipal_tab_existed === true ? (
              <Typography style={{ color: "#079455" }}>Yes</Typography>
            ) : data?.is_municipal_tab_existed === false ? (
              <Typography style={{ color: "#D92D20" }}>No</Typography>
            ) : (
              "-"
            )}
          </Descriptions.Item>
          {data?.is_municipal_tab_existed && (
            <>
              <Descriptions.Item label="Connection Type">
                {data?.connection_type}
              </Descriptions.Item>
              <Descriptions.Item label="Authorization">
                {data?.connection_authorization}
              </Descriptions.Item>

              {data?.service_number && (
                <Descriptions.Item label="Service Connection Number">
                  {data?.service_number}
                </Descriptions.Item>
              )}

              {data?.unauthorized_connection_given_on && (
                <Descriptions.Item label="Connection given on">
                  {data?.unauthorized_connection_given_on}
                </Descriptions.Item>
              )}
              {data?.status_of_meter_connection && (
                <Descriptions.Item label="Status of meter">
                  {data?.status_of_meter_connection}
                </Descriptions.Item>
              )}
            </>
          )}

          <Descriptions.Item label="Geo-coordinates tagged">
            {data?.is_geo_coordinates_submitted === true ? (
              <Typography style={{ color: "#079455" }}>Yes</Typography>
            ) : data?.is_geo_coordinates_submitted === false ? (
              <Typography style={{ color: "#D92D20" }}>No</Typography>
            ) : (
              "-"
            )}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Modal>
  );
};
export default HouseDataModal;
