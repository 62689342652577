import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Skeleton,
  Space,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import ExcelDataTable from "./ExcelDataTable";
import { USER, sampleExcelFormatUrl } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";

const { Dragger } = Upload;

const ExcelUploadPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userDetails = JSON.parse(getItem(USER));

  const props = {
    beforeUpload: (file) => {
      console.log("before-upload", file);
      const fileType =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    onChange: async (info) => {
      if (info.file.status === "done") {
        setLoading(true);
        const file = info.file.originFileObj;
        const reader = new FileReader();

        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: "array" });

          // Get the first sheet in the workbook
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          // Convert the worksheet to JSON
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            raw: false,
            header: 1,
          });

          const headers = jsonData[0];
          let data = [];

          jsonData.slice(1).map((row, rowIndex) => {
            if (row.some((r) => r)) {
              const rowData = {};
              headers.forEach((header, index) => {
                rowData[header] = row[index];
              });
              data.push({ key: rowIndex, ...rowData });
            }
          });
          setData(data);
          setLoading(false);
          message.success(`${info.file.name} file uploaded successfully`, [5]);
        };
        reader.readAsArrayBuffer(file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`);
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const displayItemRow = (title, value) => {
    return (
      <Typography style={{ fontSize: 18 }}>
        <span style={{ fontWeight: 600 }}>{title} :</span>{" "}
        <span style={{ fontSize: 16 }}>{value}</span>
      </Typography>
    );
  };

  return (
    <Row gutter={[16, 24]}>
      <Col span={8}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {displayItemRow("Secretariat Name", userDetails?.region?.name)}
          {displayItemRow("Secretariat Code", userDetails?.region?.code)}
          {displayItemRow("Secretariat Number", userDetails?.region?.number)}
          <Button
            type="primary"
            ghost
            icon={<DownloadOutlined />}
            href={sampleExcelFormatUrl}
            style={{ marginTop: 10 }}
          >
            Download Excel File Format
          </Button>
        </Space>
      </Col>
      <Col span={8}>
        <Dragger
          {...props}
          accept=".xlsx"
          maxCount={1}
          showUploadList={false}
          customRequest={dummyRequest}
        >
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className="ant-upload-text">Import Data</p>
          <p className="ant-upload-text">
            <span style={{ color: "#406AFF" }}>
              Click to {data?.length > 0 ? "Re-upload" : "Upload"}{" "}
            </span>
            or drag and drop
          </p>
          <p className="ant-upload-hint">Excel File</p>
        </Dragger>
      </Col>
      {loading ? (
        <Skeleton active />
      ) : data?.length > 0 ? (
        <Col span={24}>
          <ExcelDataTable data={data} setData={setData} />
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export default ExcelUploadPage;
