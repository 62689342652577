import React from "react";
import { Table } from "antd";
import { Link, useParams } from "react-router-dom";
import {
  secTicketColumns,
  secWlmTicketColumns,
  wlmTicketColumns,
} from "../../../constants/ticketColumns";
import { setItem } from "../../../helpers/localStorage";

const WaterLeakageTable = ({ loading, data, params, comp }) => {
  console.log(data);
  const query = useParams();
  const secColumns = [
    {
      title: "Secretariat Number",
      key: "secretariat_number",
      dataIndex: "secretariat_number",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${value}`}
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Secretariat name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${value}`}
        >
          {value}
        </Link>
      ),
    },
    ,
    ...wlmTicketColumns,
  ];
  return (
    <Table
      columns={comp?.includes("secretariat") ? secWlmTicketColumns : secColumns}
      bordered
      dataSource={data?.data}
      pagination={false}
      loading={loading}
      scroll={{
        y: 550,
        x: 0,
      }}
      summary={() => {
        return (
          <Table.Summary fixed="top">
            {!comp?.includes("secretariat") && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_counts?.total_tickets_reported}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_counts?.total_tickets_resolved}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          </Table.Summary>
        );
      }}
    />
  );
};
export default WaterLeakageTable;
