import React from "react";
import { Col, Row, Avatar, Typography } from "antd";

const { Text } = Typography;
const PieLegend = ({ data }) => {
  return (
    <Row gutter={[12, 24]}>
      {data?.map((item) => (
        <Col span={12} style={{ textAlign: "center" }}>
          <Row>
            <Col span={24}>
              <Avatar
                shape="square"
                size={18}
                style={{
                  backgroundColor:
                    item?.name === "Resolved"
                      ? "#2563EB"
                      : item?.name === "Pending" && "#F97066",
                }}
              />
            </Col>
            <Col span={24}>
              <Text style={{ fontWeight: "500", fontSize: "12px" }}>
                {item?.name}
              </Text>
            </Col>
            <Col span={24}>
              <Text style={{ fontWeight: "700", fontSize: "12px" }}>
                {item?.count}
              </Text>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};
export default PieLegend;
