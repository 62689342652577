import React from "react";
import { Dropdown, Menu, Space, Avatar } from "antd";
// import Avatar from "react-avatar";
import { Link } from "react-router-dom";

const UserAvatar = ({ user, colors }) => {
  const menu = (
    <Menu>
      {/* <Menu.Item key="0">
        <Link to="/myprofile">My Profile</Link>
      </Menu.Item> */}
      <Menu.Item key="1">
        <Link to="/logout">Logout</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Space>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Avatar
          style={{
            backgroundColor: colors[user.region?.uid % 24],
            cursor: "pointer",
            // color: "#f56a00",
          }}
          size={40}
        >
          {user?.user_name?.slice(0, 1).toUpperCase()}
        </Avatar>
      </Dropdown>
    </Space>
  );
};

export default UserAvatar;
