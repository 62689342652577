import axiosInstance from "../config/axios.config";

export function fetchGeoTaggingAPI(params) {
  return axiosInstance.get(`wqm/fetch-geo-tagging-data/?${params}`);
}
export function fetchMapAPI(params) {
  return axiosInstance.get(`wqm/map-view/?ticket=${params}`);
}
export function fetchOverviewAPI() {
  return axiosInstance.get(`wqm/dashboard-overview/`);
}

export function downloadGeoTaggingReport() {
  return axiosInstance.get(`wqm/fetch-geo-tagging-data/?response_type=file`, {
    responseType: "blob",
  });
}
