import React, { useState } from "react";
import {
  Row,
  Table,
  Col,
  Pagination,
  Breadcrumb,
  Tag,
  Space,
  Button,
  Typography,
} from "antd";
import {
  geoHouseColumns,
  geoSecColumns,
  geoStreetColumns,
} from "../../../constants/tableColumns";
import { Link, useNavigate, useParams } from "react-router-dom";
import HouseDataModal from "./HouseDataModal";

const GeoTaggingTable = ({ data, loading, page, setPage, comp, params }) => {
  const query = useParams();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const secColumns = [
    {
      title: "Secretariat Number",
      key: "secretariat_number",
      dataIndex: "secretariat_number",

      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${value}`}
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Secretariat Name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/?secretariat_name=${value}&secretariat=${record.row_id}&number=${record?.secretariat_number}`}
        >
          {value}
        </Link>
      ),
      sorter: (a, b) => a.row_name.localeCompare(b.row_name),
    },
    ...geoSecColumns,
  ];
  const streetColumns = [
    {
      title: "Street Name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}&street_name=${value}&street=${record?.row_id}`}
        >
          {value}
        </Link>
      ),
      sorter: (a, b) => a.row_name.localeCompare(b.row_name),
    },
    ...geoStreetColumns,
  ];
  const houseColumns = [
    {
      title: "Door Number",
      key: "door_no",
      dataIndex: "door_no",
      render: (value, record) => (
        <Typography.Link
          onClick={() => {
            navigate(
              `/dashboard/${query?.tab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}&street_name=${params?.street_name}&street=${params?.street}&door=${record?.row_id}&door_no=${value}`
            );
            setIsVisible(true);
          }}
        >
          {value}
        </Typography.Link>
      ),
    },
    ...geoHouseColumns,
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        {comp?.includes("door") ? (
          <Breadcrumb separator=">">
            <Breadcrumb.Item href={`/dashboard/${query?.tab}`}>
              <Space>
                {params?.secretariat_name}
                <Tag>{params?.number}</Tag>
              </Space>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/dashboard/${query?.tab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}`}
            >
              {params?.street_name}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{params?.door_no}</Breadcrumb.Item>
          </Breadcrumb>
        ) : comp?.includes("street") ? (
          <Breadcrumb separator=">">
            <Breadcrumb.Item href={`/dashboard/${query?.tab}`}>
              <Space>
                {params?.secretariat_name}
                <Tag>{params?.number}</Tag>
              </Space>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/dashboard/${query?.tab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}`}
            >
              {params?.street_name}
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          comp?.includes("secretariat") && (
            <Breadcrumb separator=">">
              <Breadcrumb.Item href={`/dashboard/${query?.tab}`}>
                <Space>
                  {params?.secretariat_name}
                  <Tag>{params?.number}</Tag>
                </Space>
              </Breadcrumb.Item>
            </Breadcrumb>
          )
        )}
      </Col>
      <Col span={24} className="container">
        <Table
          bordered
          columns={
            comp?.includes("street")
              ? houseColumns
              : comp?.includes("secretariat")
              ? streetColumns
              : secColumns
          }
          dataSource={data?.data}
          loading={loading}
          pagination={false}
          scroll={{
            y: 550,
            x: 0,
          }}
          summary={() => (
            <Table.Summary fixed="top">
              {!comp?.includes("street") && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  {!comp?.includes("secretariat") && (
                    <>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {data?.total_counts?.total_streets}
                      </Table.Summary.Cell>
                    </>
                  )}
                  {comp?.includes("secretariat") && (
                    <Table.Summary.Cell></Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell>
                    {data?.total_counts?.total_households}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {data?.total_counts?.total_households_tagged_geo_tagging}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </Table.Summary>
          )}
        />
      </Col>

      {/* <Col span={24} style={{ textAlign: "right" }}>
        <Pagination
          current={page}
          pageSize={30}
          total={data?.total_count}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col> */}
      {(isVisible || params?.door) && (
        <HouseDataModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          params={params}
          data={data}
        />
      )}
    </Row>
  );
};
export default GeoTaggingTable;
