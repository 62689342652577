import { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Result,
  Card,
  Typography,
} from "antd";
import { LockOutlined } from "@ant-design/icons";

import { Link, useLocation, useParams } from "react-router-dom";
import Logo from "../../assets/images/loginlogo.svg";
import { setPasswordAPI } from "../../services/auth.services";
import { isMobileOnly } from "react-device-detect";
import JSEncrypt from "jsencrypt";
import { publicKey } from "../../constants/defaultKeys";
import "./SetPassword.css";

const SetPassword = () => {
  const location = useLocation();
  const query = Object.fromEntries(new URLSearchParams(location?.search));
  const [password, setPassowrd] = useState(false);
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);

  const onFinish = (values) => {
    let data = {
      ...values,
      token: query && query.token,
      new_password: encrypt.encrypt(values?.new_password),
      new_password_confirm: encrypt.encrypt(values?.new_password_confirm),
    };
    setPasswordAPI(data)
      .then((response) => {
        message.success(response.data.message);
        setPassowrd(true);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  return (
    <Fragment>
      {password ? (
        <Row type="flex" justify="center" align="middle">
          <Col span={24} style={{ paddingTop: 100 }}>
            <Result
              status="success"
              title="Your password has been updated successfully please login now"
            />
          </Col>
          {!isMobileOnly && <Link to={`/`}>Login</Link>}
        </Row>
      ) : (
        <Row type="flex" justify="center" align="middle" className="login">
          <Col
            lg={{ span: 8, offset: 8 }}
            md={{ span: 12, offset: 6 }}
            sm={{ span: 20, offset: 2 }}
            xs={{ span: 20, offset: 2 }}
            style={{ marginLeft: 0 }}
          >
            <Card bordered={false}>
              <div style={{ textAlign: "center", paddingBottom: "20px" }}>
                {isMobileOnly ? (
                  <img src={Logo} alt="check logo" height="48px" />
                ) : (
                  <img src={Logo} alt="check logo" height="103px" />
                )}
              </div>
              <Form name="setpassword" onFinish={onFinish}>
                <Form.Item
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message:
                        "Use 8 or more characters with a mix of lowercase and uppercase letters, numbers & symbols.",
                      pattern: new RegExp(
                        "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                      ),
                    },
                  ]}
                >
                  <Input.Password
                    autoFocus={true}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                    size="large"
                    type="password"
                  />
                </Form.Item>
                <Form.Item
                  name="new_password_confirm"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The new passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Confirm Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button"
                  >
                    <span>Submit</span>
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
export default SetPassword;
