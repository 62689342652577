import React from "react";
import { Row, Table, Col, Pagination, Breadcrumb, Tag, Space } from "antd";
import {
  wqmHouse2Cols,
  wqmHouseCols,
  wqmSec1Columns,
  wqmSec2Columns,
} from "../../../../constants/tableColumns";
import { Link, useParams } from "react-router-dom";

const FortnightTable = ({
  data,
  loading,
  page,
  setPage,
  comp,
  params,
  date,
  setDate,
}) => {
  const query = useParams();
  const fortnightSecCols =
    query?.fortnight === "fortnight-1" ? wqmSec1Columns : wqmSec2Columns;
  const fortHouseCols =
    query?.fortnight === "fortnight-1" ? wqmHouseCols : wqmHouse2Cols;
  const secColumns = [
    {
      title: "Secretariat Number",
      key: "secretariat_number",
      dataIndex: "secretariat_number",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${value}`}
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Secretariat Name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${record?.secretariat_number}`}
        >
          {value}
        </Link>
      ),
      sorter: (a, b) => a.row_name.localeCompare(b.row_name),
    },
    ...fortnightSecCols,
  ];
  const streetColumns = [
    {
      title: "Street Name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}&street_name=${value}&street=${record?.row_id}`}
        >
          {value}
        </Link>
      ),
      sorter: (a, b) => a.row_name.localeCompare(b.row_name),
    },
    ...fortnightSecCols,
  ];
  const stColumns = streetColumns?.filter(
    (cols) => cols?.key !== "secretariat_code" && cols?.key !== "total_streets"
  );
  const houseColumns = [
    {
      title: "Household Door  Number",
      key: "door_no",
      dataIndex: "door_no",
    },
    ...fortHouseCols,
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        {comp?.includes("street") ? (
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              href={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/`}
            >
              <Space>
                {params?.secretariat_name}
                <Tag>{params?.number}</Tag>
              </Space>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}`}
            >
              {params?.street_name}
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          comp?.includes("secretariat") && (
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                href={`/dashboard/${query?.tab}/${query?.inspectiontab}/${query?.fortnight}/`}
              >
                <Space>
                  {params?.secretariat_name}
                  <Tag>{params?.number}</Tag>
                </Space>
              </Breadcrumb.Item>
            </Breadcrumb>
          )
        )}
      </Col>
      <Col span={24} className="container">
        <Table
          bordered
          className={comp?.includes("street") && "fortnight"}
          columns={
            comp?.includes("street")
              ? houseColumns
              : comp?.includes("secretariat")
              ? stColumns
              : secColumns
          }
          rowKey={(record, index) => index}
          dataSource={data?.data}
          loading={loading}
          pagination={false}
          scroll={{
            y: 550,
            x: 0,
          }}
          summary={() => {
            return (
              <Table.Summary fixed="top">
                {!comp?.includes("street") && (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    {!comp?.includes("secretariat") && (
                      <>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {data?.total_counts?.total_streets}
                        </Table.Summary.Cell>
                      </>
                    )}
                    <Table.Summary.Cell index={4}>
                      {data?.total_counts?.total_households}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {query?.tab === "fortnight1"
                        ? data?.total_counts
                            ?.total_households_completed_survey_fortnight1
                        : data?.total_counts
                            ?.total_households_completed_survey_fortnight2}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              </Table.Summary>
            );
          }}
        />
      </Col>

      <Col span={24} style={{ textAlign: "right" }}>
        <Pagination
          current={page}
          pageSize={30}
          total={data?.total_count}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col>
    </Row>
  );
};
export default FortnightTable;
