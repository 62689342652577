import React from "react";
import { Table } from "antd";
import {
  houseTicketColumns,
  secTicketColumns,
} from "../../../constants/ticketColumns";
import { Link, useParams } from "react-router-dom";

const WqmTicketTable = ({ data, loading, params, comp, page, setPage }) => {
  const query = useParams();
  const secColumns = [
    {
      title: "Secretariat Number",
      key: "secretariat_number",
      dataIndex: "secretariat_number",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${value}`}
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Secretariat name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/?secretariat_name=${record?.row_name}&secretariat=${record.row_id}&number=${record?.secretariat_number}`}
        >
          {value}
        </Link>
      ),
    },
    ,
    ...secTicketColumns,
  ];
  const filterStreetColumns = secTicketColumns?.filter(
    (cols) => cols.key !== "secretariat_code"
  );
  const streetColumns = [
    {
      title: "Street Name",
      key: "row_name",
      dataIndex: "row_name",
      render: (value, record) => (
        <Link
          to={`/dashboard/${query?.tab}/${query?.inspectiontab}/?secretariat_name=${params?.secretariat_name}&secretariat=${params?.secretariat}&number=${params?.number}&street_name=${value}&street=${record?.row_id}`}
        >
          {value}
        </Link>
      ),
      sorter: (a, b) => a.row_name.localeCompare(b.row_name),
    },
    ...filterStreetColumns,
  ];

  return (
    <Table
      columns={
        comp?.includes("street")
          ? houseTicketColumns
          : comp?.includes("secretariat")
          ? streetColumns
          : secColumns
      }
      bordered
      dataSource={data?.data}
      loading={loading}
      pagination={false}
      scroll={{
        y: 550,
        x: 0,
      }}
      summary={() => {
        return (
          <Table.Summary fixed="top">
            {!comp?.includes("street") && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {!comp?.includes("secretariat") && (
                  <>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                  </>
                )}
                <Table.Summary.Cell>
                  {data?.total_counts?.total_households}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_counts?.total_households_completed_survey}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_counts?.total_households_with_water_supply_issue}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_counts?.total_households_with_water_color_issue}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_counts?.total_households_with_water_odour_issue}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {
                    data?.total_counts
                      ?.total_households_with_chlorine_content_issue
                  }
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          </Table.Summary>
        );
      }}
    />
  );
};
export default WqmTicketTable;
