import React from "react";
import { Menu, Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { inspectionMenu } from "../../../constants/commonItems";
import WqmTabs from "./Wqm/WqmTabs";

const InspectionTabs = () => {
  const navigate = useNavigate();
  const router = useParams();
  const onClick = (e) => {
    e.key === "wqm"
      ? navigate(`/dashboard/${router?.tab}/${e.key}/fortnight-1`)
      : navigate(`/dashboard/${router?.tab}/${e.key}`);
  };
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Menu
          items={inspectionMenu}
          mode="horizontal"
          onClick={onClick}
          selectedKeys={router?.inspectiontab}
        />
      </Col>

      {router?.inspectiontab === "wqm" && <WqmTabs />}
    </Row>
  );
};
export default InspectionTabs;
